<template>
  <div id="app">
    <div class="app-container">
      <div class="header"><img src="@/assets/global/logo.png" alt="logo"><svg-icon icon-class="Nav" @click="showPopup" />
        
      </div>
      <div class="nav">
        <van-popup
          v-model="show"
          closeable
          close-icon-position="top-right"
          position="right"
          :style="{ height: '100%', width: '42.6667%' }"
        >
          <div class="nav-logo van-hairline--bottom">
            <img src="@/assets/global/nav-logo.png" alt="nav-logo">
          </div>
          <router-link class="to-home" active-class="active" to="/home">首页</router-link>
          <van-collapse v-model="activeNames" :border="false">
            <van-collapse-item title="产品介绍" name="1">
              <ul class="nav-content">
                <router-link active-class="active" tag="li" to="/residential">智慧院舍系统</router-link>
                <router-link active-class="active" tag="li" to="/homeCare">智慧居家养老系统</router-link>
                <router-link active-class="active" tag="li" to="/civilService">民政服务系统</router-link>
                <router-link active-class="active" tag="li" to="/hardware">智能硬件</router-link>
              </ul>
            </van-collapse-item>
            
          </van-collapse>

          <ul class="nav-content">
            <router-link active-class="active" tag="li" to="/invest">医养投资</router-link>
            <router-link active-class="active" tag="li" to="/serve">政企服务</router-link>
            <router-link active-class="active" tag="li" to="/news">新闻资讯</router-link>
            <router-link active-class="active" tag="li" to="/about">关于我们</router-link>
          </ul>
        </van-popup>
      </div>
      
      <div class="main">
        <div class="float" @click="downloadCode">
          <div class="float-header">
            <img src="@/assets/global/code3.png" alt="">
          </div>
          <div class="float-button"><span>扫码咨询</span><svg-icon icon-class="top"/></div>
        </div>
        <van-popup v-model="showCode" round>
          <div class="code-detail">
            <p>扫码咨询</p>
            <img src="@/assets/global/code4.png" alt="">
            <div class="btn van-hairline--top">
              <div @click="cancel" class="van-hairline--right">取消</div>
              <!-- <a href="./assets/global/code4.png" download="1">
                <div @click="handleDownload">保存二维码</div>
              </a> -->
              <div>长按图片保存</div> 
            </div>
          </div>
        </van-popup>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
        <div class="footer">
          <div class="footer-link">
            <div class="footer-link-l">
              <img src="@/assets/global/logo.png" alt="logo">
              <div class="code-item">
                <img src="@/assets/global/code1.png" alt="qrcode">
                <p>更多项目详情<br/>欢迎扫码咨询</p>
              </div>
              <div class="code-item">
                <img src="@/assets/global/newQc.jpg" alt="qrcode">
                <p>业务联系<br/>13302880921</p>
              </div>
            </div>
            <div class="footer-link-r">
              <p>友情链接</p>
              <div class="friendship-link ">
                <a href="/residential">智慧院舍系统</a>
                <a href="/invest">医养投资</a>
                <a href="/homeCare">智慧居家养老系统</a>
                <a href="/serve">政企服务</a>
                <a href="/civilService">民政服务系统</a>
                <a href="/news">新闻资讯</a>
                <a href="/hardware">智能硬件</a>
                <a href="/about">关于我们</a>
              </div>
              <p class="out-link"><a href="http://www.jiangmenrenai.com/" target="_blank">仁爱官网：<br/>http://www.jiangmenrenai.com/</a></p>
            </div>
          </div>
          <div class="footer-tips">
            <p class="tips-item">
              服务热线：<br />0750-3676788
            </p>
            <p class="tips-item">
              业务咨询：<br />13302880921
            </p>
            <p class="tips-item">
              公司地址：<br />江门市蓬江区杜阮镇江杜中路29号
            </p>
          </div>
        </div>
      </div>
    </div>
    
    
  </div>
</template>

<script>
  import Vue from 'vue';
  import { Popup, Collapse, CollapseItem } from 'vant';
  Vue.use(Popup);
  Vue.use(Collapse);
  Vue.use(CollapseItem);

  export default {
    data() {
      return {
        show: false,
        showCode: false,
        activeNames: [],
        defaultActive: ''
      };
    },
    watch: {
      $route: {
        handler(value) {
          console.log(value);
          this.defaultActive = value.path;
          // this.addSubActive();
          if(value.path == '/news') {
            this.$router.push({
              name: 'news',
              params: {
                id: 1
              }
            })
          };

          //处理路由切换时滚动条回到顶部
          //子路由不需要回到顶部要自行配置
          let notScollList = ['introduce', 'offer', 'rIntroduce', 'rOffer'];
          let isNoScoll =  notScollList.some(item => {
            if(value.name === item) {
              return true;
            }else {
              return false;
            }
          })
          // console.log(val);
          //从菜单默认跳转的子路由需要回弹
          let scroll1 = value.name === 'introduce' && value.redirectedFrom === '/homeCare';
          let scroll2 = value.name === 'rIntroduce' && value.redirectedFrom === '/residential';

          if(scroll1 || scroll2 || !isNoScoll) {
            window.scrollTo(0,0);
            let el = document.querySelector('.main');
            el.scrollTop = 0;
          }
        },
        immediate: true
      }
    },
    
    updated() {
      // this.defaultActive = this.$route.path;
      // console.log(this.defaultActive.split('/'));
      this.addSubActive();
    },
    
    
    methods: {
      showPopup() {
        this.show = true;
      },
      downloadCode() {
        this.showCode = true;
      },
      cancel() {
        this.showCode = false;
      },
      /* handleDownload() {
        console.log('down');
      }, */

      //处理菜单栏产品介绍高亮问题
      addSubActive() {
        let el = document.querySelector('.van-collapse-item');
        // console.log(el);
        let addActive = ['residential', 'homeCare', 'civilService', 'hardware'];
        let isAdd =  addActive.some(item => {
          if(this.defaultActive.split('/')[1] === item) {
            return true;
          }else {
            return false;
          }
        })
        if(el) {
          if(isAdd) {
            el.classList.add('sub-active');
          }else {
            if(el.classList.contains('sub-active') == true) {
              el.classList.remove('sub-active');
            }
          }
        }
        
      },
    },
  }
</script>

<style lang="scss">
  .app-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    .header {
      height: .48rem;
      padding: 0 .16rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        height: 100%;
        // 控制图片高质量缩放
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;

      }
      .svg-icon {
        font-size: .24rem;
      }
    }
    .nav {
      .van-popup__close-icon {
        font-size: .20rem;
        top: .12rem;
        right: .16rem;
      }
      .nav-logo {
        height: .48rem;
        padding: .1rem .2rem;
        // border-bottom: .01rem solid rgba(0, 0, 0, 0.1);
        
        > img {
          height: .28rem;
          width: .36rem;
        }
      }
      .to-home {
        display: block;
        height: .44rem;
        font-weight: 500;
        font-size: .13rem;
        color: rgba(0, 0, 0, 0.85);
        line-height: .44rem;
        padding-left: .2rem;
        &.active {
          color: #00ADF6;
        }

      }
      .van-collapse {
        .van-collapse-item {
          
          .van-collapse-item__title {
            padding-left: .2rem;
            align-items: center;
            
          }
          .van-cell {
            padding: .1rem .2rem;
            font-size: .13rem;
            font-weight: 500;
            line-height: .24rem;
          }
          .van-cell__title {
            flex: none;
            > span {
              font-size: .13rem;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
            }
          }
          .van-icon {
            color: rgba(0, 0, 0, 0.85);
            font-size: .12rem;
          }
        }
      }
      .nav-content {
        > li {
          height: .44rem;
          font-weight: 500;
          font-size: .13rem;
          color: rgba(0, 0, 0, 0.85);
          line-height: .44rem;
          padding-left: .2rem;
        }
        .active {

          color: #00ADF6;
        }
      }
    }
    .sub-active {
      .van-cell__title {
        > span {
          color: #00ADF6 !important;
        }
      }
      .van-icon {
        color: #00ADF6 !important;
        
      }
    }
    .main {
      flex: 1;
      overflow: auto;
      .float {
        position: fixed;
        bottom: .8rem;
        right: .12rem;
        z-index: 999;
        .float-header {
          width: .65rem;
          height: .65rem;
          border-radius: .06rem;
          text-align: center;
          padding-top: .05rem;
          -webkit-box-shadow: 0 .0427rem .128rem rgba(0, 0, 0, 0.04);
          -moz-box-shadow: 0 0.0427rem .128rem rgba(0, 0, 0, 0.04);
          box-shadow: 0 .0427rem .128rem rgba(0, 0, 0, 0.04);
          background-color: #FFFFFF;
          > img {
            width: .55rem;
            
          }
        }
        .float-button {
          position: relative;
          bottom: .04rem;
          right: .045rem;
          width: .74rem;
          height: .2rem;
          border-radius: .1rem;
          background-color: #00ACF6;
          color: #FFFFFF;
          font-size: .12rem;
          font-weight: 500;
          text-align: center;
          line-height: .2rem;
          > span {
            display: inline-block;
            -webkit-transform-origin-x: 0;
            -webkit-transform: scale(0.92);
            transform: scale(0.92);
            transform-origin: center;
          }
          .svg-icon {
            font-size: .08rem;
            margin-left: .02rem;
            margin-bottom: .01rem;
          }
        }
      }
      .van-popup--round {
        border-radius: 0.08rem;
      }
      .code-detail {
        height: 3.32rem;
        width: 3.15rem;
        background-color: #FFFFFF;
        text-align: center;
        box-sizing: border-box;
        padding-top: .20rem;
        overflow: hidden;
        > p {
          font-size: .18rem;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          text-align: center;
          margin-bottom: .2rem;
        }
        > img {
          width: 1.9rem;
          margin-bottom: .2rem;
          -webkit-touch-callout:default;
        }
        .btn {
          display: flex;
          > div {
            flex: 1;
            height: .56rem;
            font-size: .18rem;
            line-height: .56rem;
            font-weight: 400;
            &:last-child {
              color: #00ACF6;
            }
          }
        }
      }
      .footer {
        background: #F8F8F8;
        padding: 0 .16rem;
        overflow: hidden;
        .footer-link {
          display: flex;
          justify-content: space-between;
          border-bottom: .01rem solid rgba(0, 0, 0, 0.1);
          .footer-link-l {
            > img {
              margin: .12rem 0;
              height: .48rem;
              image-rendering: -moz-crisp-edges;
              image-rendering: -o-crisp-edges;
              image-rendering: -webkit-optimize-contrast;
              image-rendering: crisp-edges;
              -ms-interpolation-mode: nearest-neighbor;
            }
            .code-item {
              margin-bottom: .16rem;
              > img {
                height: .56rem;
                /* image-rendering: -moz-crisp-edges;
                image-rendering: -o-crisp-edges;
                image-rendering: -webkit-optimize-contrast;
                image-rendering: crisp-edges;
                -ms-interpolation-mode: nearest-neighbor; */
                margin-bottom: .03rem;
              }
              > p {
                color: rgba(0, 0, 0, 0.6);
                font-size: .11rem;
                line-height: .18rem;
              }
            }
          }
          .footer-link-r {
            > p {
              margin: .32rem 0 .2rem 0;
              color: rgba(0, 0, 0, 0.6);
              font-size: .12rem;
            }
            .friendship-link {
              > a {
                color: rgba(0, 0, 0, 0.6);
                font-size: .12rem;
                line-height: .18rem;
                margin-bottom: .2rem;
              }
              
              width: 1.67rem;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              border-bottom: .01rem solid rgba(0, 0, 0, 0.1);
            }
            .out-link {
              > a {
                color: rgba(0, 0, 0, 0.6);
                font-size: .12rem;
                line-height: .18rem;
                margin-bottom: .2rem;
              }
              margin: .2rem 0 .28rem 0;
            }
          }
          
        }
        .footer-tips {
          width: 100%;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          margin: 0.28rem 0;
          .tips-item {
            color: rgba(0, 0, 0, 0.6);
            font-size: .12rem;
            line-height: .18rem;
            -webkit-transform-origin-x: 0;
            -webkit-transform: scale(0.92);
            transform: scale(0.92);
            
            box-sizing: border-box;
            &:last-child {
              transform-origin: right;
            }
            &:nth-child(2) {
              transform-origin: center;
            }
          }

        }
      }
    }

  }
</style>