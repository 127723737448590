import axios from 'axios';

class Http {
    constructor() {
        this.instance = axios.create({
            // baseURL: 'http://192.168.3.196:8080/api',
			baseURL: 'https://mallapitest.rencaijituan.com',
            timeout: 60000,
            headers: {
                "Content-Type": "application/json"
            }
        });
        this.url = 'https://mallapitest.rencaijituan.com';
    };
    

    get({url, params = {}}) {
        return new Promise((resolve, reject) => {
            this.instance.get(this.url + url, {
                params
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    };

    post({url, params = {}}) {
        return new Promise((resolve, reject) => {
            this.instance.post(this.url + url, params)
              .then((res) => {
                resolve(res.data);
              })
              .catch((err) => {
                reject(err);
              });
          });
    }
}

export default Http;