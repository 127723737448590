import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home.vue')
  },
  {
    path: '/invest',
    name: 'invest',
    component: () => import(/* webpackChunkName: "invest" */ '@/views/invest/Invest.vue')
  },
  {
    path: '/serve',
    name: 'serve',
    component: () => import(/* webpackChunkName: "serve" */ '@/views/serve/Serve.vue')
  },
  {
    path: '/civilService',
    name: 'civilService',
    component: () => import(/* webpackChunkName: "civilService" */ '@/views/civilService/CivilService.vue')
  },
  {
    path: '/hardware',
    name: 'hardware',
    component: () => import(/* webpackChunkName: "hardware" */ '@/views/hardware/Hardware.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/about/About.vue')
  },
  {
    path: '/homeCare',
    name: 'homeCare',
    redirect: '/homeCare/introduce',
    component: () => import(/* webpackChunkName: "homeCare" */ '@/views/homeCare/HomeCare.vue'),
    children: [
      {
        path: 'introduce',
        name: 'introduce',
        component: () => import(/* webpackChunkName: "introduce" */ '@/views/homeCare/Introduce.vue')
      },
      {
        path: 'offer',
        name: 'offer',
        component: () => import(/* webpackChunkName: "offer" */ '@/views/homeCare/Offer.vue')
      }
    ]
  },
  {
    path: '/residential',
    name: 'residential',
    redirect: '/residential/rIntroduce',
    component: () => import(/* webpackChunkName: "residential" */ '@/views/residential/Residential.vue'),
    children: [
      {
        path: 'rIntroduce',
        name: 'rIntroduce',
        component: () => import(/* webpackChunkName: "rIntroduce" */ '@/views/residential/RIntroduce.vue'),
        meta:{
          keepAlive:true
        }
      },
      {
        path: 'rOffer',
        name: 'rOffer',
        component: () => import(/* webpackChunkName: "rOffer" */ '@/views/residential/ROffer.vue')
      }
    ]
  },
  {
    path: '/news/:id',
    name: 'news',
    props: true,
    // redirect: '/news/1',
    component: () => import(/* webpackChunkName: "news" */ '@/views/news/News.vue'),
    meta:{
      keepAlive:true
    }
    
  },
  {
    path: '/newsDetail/:id',
    name: 'newsDetail',
    props: true,
    component: () => import(/* webpackChunkName: "news" */ '@/views/news/NewsDetail.vue'),
    
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  /* scrollBehavior(to, from, savedPosition) {
    
    return {x: 0, y: 0}
  } */
})

export default router
