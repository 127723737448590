import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/reset.css'
import '@/assets/global/icons' // icon
import Http from '@/utils/http';

router.beforeEach((to, from, next) => {
  // 让页面回到顶部
   // chrome
   document.body.scrollTop = 0;
   // firefox
   document.documentElement.scrollTop = 0;
   // safari
   window.pageYOffset = 0;
  
 next();
})


Vue.config.productionTip = false;
Vue.prototype.$http = new Http();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
